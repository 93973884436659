import {useEffect, useMemo, useState} from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

dayjs.locale('fr');

export default function Date({
  date,
  format,
}: {
  date: string;
  format?: string;
}) {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);


  const formattedDate = useMemo(() => {
    if (!date) {
      return '';
    }

    return dayjs(date).format(format ? format : 'DD/MM/YY');
  }, [date, format]);

  if (!isClient) {
    return null;
  }

  return <>{formattedDate}</>;
}
