import Date from "@components/app/Date";
import React from "react";

export default function LastUpdate({
                                     firstUpdate,
                                     lastUpdate,
                                     format = 'DD MMMM YYYY',
  updatedBy = null
}: {
  firstUpdate: string,
  lastUpdate: string,
  format?: string,
  updatedBy?: string,
}) {
  const isUpdate = firstUpdate !== lastUpdate;
  return <>
    {isUpdate ? 'Mise à jour' : 'Publiée'}
    {updatedBy && ' par ' + updatedBy}
    {' le '}
    <Date date={lastUpdate} format={format} />
  </>
}

